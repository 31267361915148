import React, { useState, useEffect, lazy, Suspense } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import { projectAuth } from './firebase/config';

const Contact = lazy(() => import('./components/Contact'));
const Footer = lazy(() => import('./components/Footer'));
const Home = lazy(() => import('./components/home/Home'));
const Materials = lazy(() => import('./components/home/Materials'));
const Navbar = lazy(() => import('./components/navbar/Navbar'));
const PageBreak = lazy(() => import('./components/home/PageBreak'));
const Photos = lazy(() => import('./components/home/Photos'));
const SignIn = lazy(() => import('./components/admin/SignIn'));
const UploadPhotos = lazy(() => import('./components/admin/UploadPhotos'));
const Shop = lazy(() => import('./components/Shop'));
const About = lazy(() => import('./components/home/About'));

const App = () => {
	const [currentUser, setCurrentUser] = useState(null);

	useEffect(() => {
		if (projectAuth) {
			projectAuth.onAuthStateChanged((authUser) => {
				if (authUser) {
					setCurrentUser(authUser.email);
				} else {
					setCurrentUser(null);
				}
			});
		}
	}, []);

	const renderLoader = () => <div>loading...</div>;

	return (
		<Router>
			<Suspense fallback={renderLoader()}>
				<Navbar />

				<Switch>
					<Route path="/shop">
						<Shop />
					</Route>

					<Route path="/contact">
						<Contact />
					</Route>

					<Route path="/signIn">
						{currentUser ? <Redirect to="/upload" /> : <SignIn />}
					</Route>

					<Route path="/upload">
						{currentUser ? <UploadPhotos /> : <SignIn />}
					</Route>

					<Route path="/">
						<Home />
						<PageBreak />
						<About />
						<Materials />
						<Photos />
					</Route>
				</Switch>

				<Footer currentUser={currentUser} />
			</Suspense>
		</Router>
	);
};

export default App;
