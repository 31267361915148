import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

// Your web app's Firebase configuration
var firebaseConfig = {
	apiKey: 'AIzaSyABiauG_f3pv4AM1X69kroVF3bYNP4mjd8',
	authDomain: 'appalachian-upholstery.firebaseapp.com',
	databaseURL: 'https://appalachian-upholstery.firebaseio.com',
	projectId: 'appalachian-upholstery',
	storageBucket: 'appalachian-upholstery.appspot.com',
	messagingSenderId: '554499729080',
	appId: '1:554499729080:web:3453b98783b7c975c6364d',
	measurementId: 'G-6PBCKSMKMY',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, projectAuth, timestamp };
